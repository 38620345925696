
import { prop, Vue } from "vue-class-component";
import { PropType } from "vue";
import debounce from "lodash/debounce";

class Props {
  onInput = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  value = prop<string>({
    default: "",
    type: String
  });
  onClear = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  minChar = prop<number>({
    default: 3,
    type: Number
  });
  timeout = prop<number>({
    default: 500,
    type: Number
  });
}
export default class Search extends Vue.with(Props) {
  onSearch = debounce(event => {
    const value = event.target.value;
    if (value.length >= this.minChar || !value) {
      this.onInput(value);
    }
  }, this.timeout);
}
